import '@/styles/globals.css';
import { CacheProvider } from '@emotion/react';
import { cache } from '@emotion/css';
import type { AppProps } from 'next/app';
import { IsAppContext } from '@/hooks/useInAppLinking';
import { wrapper } from 'lib/store';

function App({ Component, pageProps }: AppProps) {
  return (
    <CacheProvider value={cache}>
      <IsAppContext.Provider value={false}>
        <Component {...pageProps} />
      </IsAppContext.Provider>
    </CacheProvider>
  );
}

export default wrapper.withRedux(App);
