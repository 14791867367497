import {
  BaseQueryFn,
  createApi,
  FetchArgs,
  fetchBaseQuery,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import { HYDRATE } from 'next-redux-wrapper';
import { AppState } from 'lib/store';

const baseQuery: BaseQueryFn<
  string | FetchArgs,
  unknown,
  FetchBaseQueryError
> = async (args, WebApi, extraOptions) => {
  const rawBaseQuery = fetchBaseQuery({
    baseUrl:
      process.env['NEXT_REWRITE_API_URL'] ??
      process.env['NEXT_PUBLIC_API_URL'] ??
      'https://api.punch.shadowboxer.zone',
    credentials: 'include',
    prepareHeaders: (headers, { getState }) => {
      // Get the cookies from the store for SSR
      const { cookie } = (getState() as AppState).session;
      if (cookie) {
        headers.set('cookie', cookie);
      }
      return headers;
    },
  });
  return rawBaseQuery(args, WebApi, extraOptions);
};
// Define a service using a base URL and expected endpoints
export const api = createApi({
  reducerPath: 'api',
  baseQuery,
  extractRehydrationInfo(action, { reducerPath }) {
    if (action.type === HYDRATE) {
      return action.payload[reducerPath];
    }
  },
  tagTypes: [
    'CurrentUser',
    'CurrentUserOnboarding',
    'SessionInfo',
    'MFASettings',
  ],
  endpoints: _builder => ({}),
});

export default api;
